<template>
  <div class="trme_Product">
    <img class="bg" :src="data.url+'trme/bg.png'" alt="图片加载失败">
    <div class="logo">
      <img :src="data.url+'trme/trmeTitle.png'" alt="图片加载失败">
    </div>
    <div class="title">
      为您呈现 TRME<br/>
      产品介绍与产品美图
    </div>
    <div class="areaBox">
      <img class="titleImg" :src="data.url+'trme/title2.png'" alt="" srcset="">
      <div class="row">
        <div class="navText" @click="jumpPath('des1')">新品来袭，TRME体<br>型管理系列产品上市</div>
        <img :src="data.url+'trme/arrow.png'"  @click="jumpPath('des1')" alt="" class="arrow">
        <div class="line"></div>
        <div class="navText"  @click="jumpPath('des2')">
          <div>体型管理不用愁，</div>
          <div>即刻探秘TRME</div></div>
        <img :src="data.url+'trme/arrow.png'"  @click="jumpPath('des2')" alt="" class="arrow">
      </div>
    </div>
    <div class="imgBox">
      <img class="titleImg" :src="data.url+'trme/title1.png'" alt="" srcset="">
      <div class="rowImg">
        <div class="imgItem"  @click="jumpPath('img')">
          <img :src="data.url+'trme/product.png'" alt="" srcset="">
          <div class="info">
            <div class="text">产品纯图</div>
            <img :src="data.url+'trme/arrow.png'" alt="" class="arrow">
          </div>
        </div>
        <div class="imgItem"  @click="jumpPath('model')">
          <img :src="data.url+'trme/model.png'" alt="" srcset="">
          <div class="info">
            <div class="text">产品模特图</div>
            <img :src="data.url+'trme/arrow.png'" alt="" class="arrow">
          </div>
        </div>
      </div>
    </div>
    <div class="empty"></div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url+'trme/backBtn.png'" alt="">
    </div>
  </div>
</template>
<script setup>
  import {reactive,onMounted} from 'vue';
  import { useRouter } from "vue-router";
  import {baiduTotal} from "../../js/common.js";
  const router = useRouter();
  let data=reactive({
    url:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/',//图片路径
    linkObj:{
      'des1':'https://mp.weixin.qq.com/s/nNFvjscSwqHZN2JXC0-d6w',
      'des2':'https://mp.weixin.qq.com/s/cUieOFP2BvM2k8mfb5AWqA',
      'img':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0306-MkVBZwAABwBSBO3uMTNLiguUXV577&idx=0&sn=b228b84d4e06621dc6fd68a77dba95df&version=4.1.3.6008&platform=win',
      'model':'https://open.work.weixin.qq.com/wwopen/mpnews?mixuin=kTWCBgAABwC5PVnnAAAUAA&mfid=WW0306-bzfzfAAABwAPdl-12U75kQRpIot77&idx=0&sn=2028e2b4a9b198b5ea74649577034dc3&version=4.1.3.6008&platform=win'
    }
  })
  //路径跳转
  let jumpPath=(type)=>{
    window.location.href=data.linkObj[type];
  }
  //返回首页
  let backIndex=()=>{
    router.replace('/index');
  }
  onMounted(()=>{
    baiduTotal();
  })
</script>
<style lang="scss" scoped>
.trme_Product{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-top: 0.48rem;
  background: #C1DBC2;
  .bg{
    width: 100%;
    position: absolute;
    top: -0.9rem;
    left: 0;
    z-index: 1;
  }
  .logo{
    width: 2.22rem;
    overflow: hidden;
    position: relative;
    z-index: 10;
    font-size: 0;
    margin: 0 auto;
    img{
      max-width: 100%;
    }
  }
  .title{
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #02CCBE;
    line-height: 0.18rem;
    margin: 0.25rem 0 0.24rem;
    position: relative;
    z-index: 2;
  }
  .areaBox{
    width: 3.5rem;
    background: linear-gradient(90deg, #00A99B, #06CFBE, #00A99C);
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem 0.06rem 0.1rem;
    box-sizing: border-box;
    margin:0 auto 0.12rem;
    position: relative;
    z-index: 2;
    .titleImg{
      height: 0.27rem;
      text-align: center;
      margin-bottom: 0.19rem;
      img{
        height: 100%;
      }
    }
    .row{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: linear-gradient(90deg, #CCCCCC 0%, #FFFFFF 50%, #CCCCCC 100%);
      box-shadow: 2px 2px 16px 0px #09C6B9;
      border-radius:0.1rem;
      padding: 0.1rem 0.1rem;
      box-sizing: border-box;
      .navText{
        flex: 1;
        width: 0;
        font-size: 0.12rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 0.16rem;
        text-align: center;
      }
      .arrow{
        width: 0.22rem;
        height: 0.22rem;
        margin-left: 0.04rem;
      }
      .line{
        width: 1px;
        height: 0.43rem;
        background: rgba(35, 24, 21, .2);
        margin: 0 0.12rem;
      }
    }
  
  }
  .imgBox{
    width: 3.5rem;
    background: linear-gradient(90deg, #00A99B, #06CFBE, #00A99C);
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem 0 0.05rem;
    box-sizing: border-box;
    margin:0 auto 0.12rem;
    position: relative;
    z-index: 2;
    .titleImg{
      width: 1.19rem;
      height: 0.27rem;
      text-align: center;
      margin-bottom: 0.19rem;
      img{
        height: 100%;
      }
    }
    .rowImg{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .imgItem{
        flex: 1;
        width: 0;
        height: 2.01rem;
        position: relative;
        .info{
          position: absolute;
          left: 0;
          bottom: 0.04rem;
          width: 100%;
          height: 0.5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 0.17rem 0 0.3rem;
          box-sizing: border-box;
          .text{
            flex: 1;
            width: 0;
            font-size: 0.12rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            text-align: center;
          }
          .arrow{
            width: 0.22rem;
            height: 0.22rem;
          }
        }
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .backBtn{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img{
      width: 100%;
    }
  }
}
</style>